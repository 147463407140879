import React from "react"
import "./price.css"

const Price = () => {
  return (
    <>
      
    </>
  )
}

export default Price
