import React from "react";

const Heading = ({ title, subtitle }) => {
  return (
    <>
      <div className="heading">
        <h1>{title}</h1>
      <p style={{color:'#000000',background:'#ffff' , fontWeight:'bold'}} className="subtitle">{subtitle}</p>
      </div>
    </>
  );
};

export default Heading;
