export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "blog",
    path: "/blog",
  },

  {
    text: "contact",
    path: "/contact",
  },
];
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Construction",
    total: "122 Property",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Interior Designs",
    total: "155 Property",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Electrical Works",
    total: "300 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Plumbing",
    total: "80 Property",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Painting Works",
    total: "80 Property",
  },
];
export const list = [
  {
    id: 1,
    cover: "../images/list/p-1.jpg",
    name: "Construction",
    About: "Professional expertise for any project - Trust our team of experts to handle your commercial or residential construction.",
 },
  {
    id: 2,
    cover: "../images/list/p-2.png",
    name: "Interior Works",
    About: "Let us bring your dream home to life with our exceptional interior design services. Trust us to transform your space into a reflection of your personality, style, and vision.",

  },
  {
    id: 3,
    cover: "../images/list/p3.jpg",
    name: "Electrical Works",
    About: "Electrify your space with our professional electrical expertise & Experience the power of seamless electrical integration."
  },
  {
    id: 4,
    cover: "../images/list/p4.jpg",
    name: "Painting Works",
    About: "Transform your home with a fresh coat of paint and bring new life to your living space. Trust our expert painters to deliver flawless results and enhance the beauty of your house. "
  },
  {
    id: 5,
    cover: "../images/list/p5.jpg",
    name: "Plumbing Works",
    About:"Trust us for dependable and efficient plumbing services & Experience Our skilled plumbers who deliver excellence."
  },
  {
    id: 6,
    cover: "../images/list/p-6.png",
    name: "Wood Works",
    About: "Unleash the Elegance of Wood: Customized Artistry for Your Space, We Design with Nature's Finest Material."
  },
];
export const awards = [
  {
    icon: <i className="fa-solid fa-trophy"></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i className="fa-solid fa-briefcase"></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i className="fa-solid fa-lightbulb"></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i className="fa-solid fa-heart"></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
];
export const location = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-4.png",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-5.png",
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-6.png",
  },
];
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [
      <i className="fa-brands fa-facebook-f"></i>,
      <i className="fa-brands fa-linkedin"></i>,
      <i className="fa-brands fa-twitter"></i>,
      <i className="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [
      <i className="fa-brands fa-facebook-f"></i>,
      <i className="fa-brands fa-linkedin"></i>,
      <i className="fa-brands fa-twitter"></i>,
      <i className="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [
      <i className="fa-brands fa-facebook-f"></i>,
      <i className="fa-brands fa-linkedin"></i>,
      <i className="fa-brands fa-twitter"></i>,
      <i className="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [
      <i className="fa-brands fa-facebook-f"></i>,
      <i className="fa-brands fa-linkedin"></i>,
      <i className="fa-brands fa-twitter"></i>,
      <i className="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [
      <i className="fa-brands fa-facebook-f"></i>,
      <i className="fa-brands fa-linkedin"></i>,
      <i className="fa-brands fa-twitter"></i>,
      <i className="fa-brands fa-instagram"></i>,
    ],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [
      <i className="fa-brands fa-facebook-f"></i>,
      <i className="fa-brands fa-linkedin"></i>,
      <i className="fa-brands fa-twitter"></i>,
      <i className="fa-brands fa-instagram"></i>,
    ],
  },
];

export const footer = [
  {
    title: "LAYOUTS",
    text: [
      { list: "Home Page" },
      { list: "About Page" },
      { list: "Service Page" },
      { list: "Property Page" },
      { list: "Contact Page" },
      { list: "Single Blog" },
    ],
  },
  {
    title: "ALL SECTIONS",
    text: [
      { list: "Headers" },
      { list: "Features" },
      { list: "Attractive" },
      { list: "Testimonials" },
      { list: "Videos" },
      { list: "Footers" },
    ],
  },
  {
    title: "COMPANY",
    text: [
      { list: "About" },
      { list: "Blog" },
      { list: "Pricing" },
      { list: "Affiliate" },
      { list: "Login" },
      { list: "Changelog" },
    ],
  },
];
