import React from "react";
import { footer } from "../../data/Data";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footerContact">
        <div className="container">
          <div className="flex  flex-col md:flex-row justify-around">
            <div className="text">
              <h1 className="">Do You Have Questions ?</h1>
              <p>We'll help you to grow your career and growth.</p>
            </div>
            <Link to={"/contact"} className='m-3 md:m-0'>
              <button className="btn5">Contact Us Today</button>
            </Link>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="box">
            <div className="logo">
              <img src="../images/logo1.png" className="rounded-lg mb-4" alt="" />
              <h2>Do You Need Help With Anything?</h2>
              {/* <p>
                Receive updates, hot deals, tutorials, discounts sent straignt
                in your inbox every month
              </p> */}
              <h2>Address</h2>
              <p>
              1-401/5 Canara Bank ATM building, Munekolala busstand, Marathahalli, Bangalore - 560037
              </p>

              <div className="input flex">
              </div>
            </div>
          </div>

          {footer.map((val, index) => (
            <div key={index} className="box">
              <h3>{val.title}</h3>
              <ul>
                {val.text.map((items, idx) => (
                  <li key={idx}> {items.list} </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
      <div className="legal">
        <span>© 2023 AgileConstrctions.com  Designd By DileepReddy.</span>
      </div>
    </>
  );
};

export default Footer;
