import React from "react"
import { list } from "../../data/Data"
import "./recent.css"
const RecentCard = () => {
  return (
    <>
      <div className='content grid3 mtop' >
        {list.map((val, index) => {
          const { cover, category,  name, price, type,About } = val
          return (
            <div className='box shadow' key={index}>
              <div className='img'>
                <img src={cover} alt='' />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{ background: category === "For Sale" ? "#25b5791a" : "#ff98001a", color: category === "For Sale" ? "#25b579" : "#ff9800" }}>{category}</span>
                </div>
                <div className="h3">
                <h3>{name}</h3>
                <div className="h4">
                <h4>{About}</h4>
                </div>
                </div>
              </div>
              <div className='button flex'>
                <div>
                  <button className='btn2'>{price}</button> <label htmlFor=''></label>
                </div>
                <span>{type}</span>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default RecentCard
