import React from "react"

import "./team.css"

const Team = () => {
  return (
    <div>
      
    </div>
  )
}

export default Team
