import React from "react";
import img from "../images/pricing.jpg";
import Back from "../common/Back";
import "./contact.css";
import Emailjs from "emailjs-com";

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    Emailjs.sendForm(
      "service_rdx1rog",
      "template_e042pwk",
      e.target,
      "WfxPtzf_wU5lEqBWA"
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }
  return (
    <>
      <section className="contact mb">
        <Back
          name="Contact Us"
          title="Get Helps & Friendly Support"
          cover={img}
        />
        <div className="container">
          <form className="shadow"  onSubmit={sendEmail}>
            <h4>Fillup The Form</h4> <br />
            <div>
              <input type="text" name="name" placeholder="Name" />
              <input type="text" name="user_email" placeholder="Email" />
            </div>
            <input type="text" name="phno" placeholder="+91xxxxxxxx" />
            <textarea name="message" cols="30" rows="10"/>
            <button type="submit">Submit</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
